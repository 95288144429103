import React, { Component } from 'react'
import { Link } from "gatsby"
import { graphql, StaticQuery } from 'gatsby'
import Fade from "react-reveal/Fade"

import { useSelector } from 'react-redux'

import Layout from "../layouts/App"
import SEO from "../components/seo"

import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const settings = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [{
            breakpoint: 980,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}

const snapConfig = {
    scrollSnapDestination: "0% 100%",
    scrollTimeout: 300,
    scrollTime: 600
}

export default ({pageContext}) => {
    return (
      <Layout>
            <SEO title="Home" description={pageContext.node.acf.metaPageDescription}/>
            <article className="home_page"> 
             {/* id="container" ref={this.container} */}
                <StaticQuery query={graphql`
                    {
                        languageEN: allWpWork(filter: {acf: {isFeatured: {eq: true}},languageCode:{eq:"en"}}, limit: 4, sort: { fields: [date], order: DESC }) {
                            edges {
                                node {
                                    id
                                    title
                                    slug
                                    acf {
                                        workExcerpt
                                        workImage {
                                            sourceUrl
                                            srcSet
                                            mediaDetails {
                                                sizes {
                                                  name
                                                  sourceUrl
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        languageJA: allWpWork(filter: {acf: {isFeatured: {eq: true}},languageCode:{eq:"ja"}}, limit: 4, sort: { fields: [date], order: DESC }) {
                            edges {
                                node {
                                    id
                                    title
                                    slug
                                    acf {
                                        workExcerpt
                                        workImage {
                                            sourceUrl
                                            srcSet
                                            mediaDetails {
                                                sizes {
                                                  name
                                                  sourceUrl
                                                }
                                              }
                                        }
                                    }
                                }
                            }
                        }
                        languageKO: allWpWork(filter: {acf: {isFeatured: {eq: true}},languageCode:{eq:"ko"}}, limit: 4, sort: { fields: [date], order: DESC }) {
                            edges {
                                node {
                                    id
                                    title
                                    slug
                                    acf {
                                        workExcerpt
                                        workImage {
                                            sourceUrl
                                            srcSet
                                            mediaDetails {
                                                sizes {
                                                  name
                                                  sourceUrl
                                                }
                                              }
                                        }
                                    }
                                }
                            }
                        }
                        homepageConfigEN: allWpPage(
                            filter: {template: {templateName: {eq: "Home"}}, languageCode: {eq: "en"}}
                          ) {
                            edges {
                              node {
                                config {
                                  video_id
                                  video_type
                                  post_id
                                  show_japanese
                                  show_korean
                                  home_description
                                  poster {
                                    _large
                                    _full
                                  }
                                }
                                related_office {
                                  relatedOffice
                                }
                              }
                            }
                          }
                        homepageConfigJA: allWpPage(
                            filter: {related_office: {relatedOffice: {eq: "wktokyo"}}, template: {templateName: {eq: "Home"}}, languageCode: {eq: "ja"}}
                          ) {
                            edges {
                              node {
                                config {
                                  video_id
                                  video_type
                                  post_id
                                  show_japanese
                                  show_korean
                                  home_description
                                  poster {
                                    _large
                                    _full
                                  }
                                }
                              }
                            }
                          }
                        homepageConfigKO: allWpPage(
                            filter: {related_office: {relatedOffice: {eq: "wkseoul"}}, template: {templateName: {eq: "Home"}}, languageCode: {eq: "ko"}}
                          ) {
                            edges {
                              node {
                                config {
                                  video_id
                                  video_type
                                  post_id
                                  show_japanese
                                  show_korean
                                  home_description
                                  poster {
                                    _large
                                    _full
                                  }
                                }
                              }
                            }
                          }
                        featuredNewsEN: allWpNews(filter: {acf: {isFeatured: {eq: true}}, languageCode: {eq: "en"}}, limit: 6, sort: { fields: [date], order: DESC }) {
                            edges {
                                node {
                                    id
                                    title
                                    slug
                                    acf {
                                        newsExcerpt
                                        newsImage {
                                            id
                                            sourceUrl
                                            srcSet
                                            mediaDetails {
                                                sizes {
                                                    sourceUrl                                        
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        featuredNewsJA: allWpNews(filter: {acf: {isFeatured: {eq: true}}, languageCode: {eq: "ja"}}, limit: 6, sort: { fields: [date], order: DESC }) {
                            edges {
                                node {
                                    id
                                    title
                                    slug
                                    acf {
                                        newsExcerpt
                                        newsImage {
                                            id
                                            sourceUrl
                                            srcSet
                                            mediaDetails {
                                                sizes {
                                                    sourceUrl                                        
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        featuredNewsKO: allWpNews(filter: {acf: {isFeatured: {eq: true}}, languageCode: {eq: "ko"}}, limit: 6, sort: { fields: [date], order: DESC }) {
                            edges {
                                node {
                                    id
                                    title
                                    slug
                                    acf {
                                        newsExcerpt
                                        newsImage {
                                            id
                                            sourceUrl
                                            srcSet
                                            mediaDetails {
                                                sizes {
                                                    sourceUrl                                        
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                `} render={props => {
                    const langKey = useSelector(state => state.langKey.language)
                    
                    let urlBase
                    if(process.env.GATSBY_OFFICE_NAME === 'WK_SEOUL'){
                        urlBase = langKey === "ko" ? "" : `/${langKey}`
                    }
                    else{
                        urlBase = langKey === "ja" ? "" : `/${langKey}`
                    }

                    if(pageContext.urlBase === 'ja'){
                        return(
                            <>
                            
                            {props.homepageConfigJA.edges.map(homepage => {
                                return(
                                    <section className="home_page__Featured home_page__Featured__Video" key={homepage.node.id} style={{backgroundImage: "url(" + homepage.node.config.poster._full + ")"}}>
                                        <iframe src={`https://player.vimeo.com/video/${homepage.node.config.video_id}?app_id=122963&background=1&player_id=player`} width="1280" height="720" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen title="W+K Tokyo Website" id="player" crossOrigin="anonymous"></iframe>
                                        <div className="home_page__Featured__blackOverlay"></div>
                                        <Fade bottom distance={`100px`}>
                                            <div className="home_page__Featured__Content container-fluid-custom-only">
                                                <h2>{homepage.node.config.home_description}</h2>
                                            </div>
                                        </Fade>
                                    </section>
                                )
                                })}
                                {props.languageJA.edges.map((work, index) => {
                                    if(index === 0){
                                        return(
                                        <React.Fragment key={work.node.id}>
                                            <section className="home_page__Featured">
                                                {/* {work.node.acf.workImage.mediaDetails.sizes.map(imageSrc => {
                                                    for(let i=0; i<5; i++){
                                                        if(imageSrc.name === 'large'){
                                                            return <img src={imageSrc.sourceUrl} alt="" />
                                                            i = 4;
                                                        }else if(imageSrc.name === 'medium') {
                                                            return <img src={imageSrc.sourceUrl} alt="" />
                                                            i = 4;
                                                        }  
                                                        i++;                                             
                                                    }
                                                })} */}
                                                <img src={work.node.acf.workImage.sourceUrl} srcSet={work.node.acf.workImage.srcSet} alt="" />
                                                {/* <img src={work.node.acf.workImage.sourceUrl} alt="" /> */}
                                                <div className="home_page__Featured__blackOverlay"></div>
                                                <Fade bottom distance={`100px`}>
                                                <div className="home_page__Featured__Content container-fluid-custom-only">
                                                    <h2><Link to={`${urlBase}/work/${work.node.slug}`}>{work.node.acf.workExcerpt}</Link></h2>
                                                    <Link to={`${urlBase}/work/${work.node.slug}`}  className="home__Featured__Link">続きを見る</Link>
                                                </div>
                                                </Fade>
                                            </section>
                                            <section className="news_Home_Page">
                                                <Fade bottom distance={`100px`}>
                                                <div className="slick_slider container-fluid-custom-only">
                                                    <h3>最近のニュース</h3>
                                                    <div className="slider">
                                                        <Slider {...settings}>
                                                            {props.featuredNewsJA.edges.map(news => {
                                                                return(
                                                                    <div className="slides" key={news.node.id}>
                                                                        <div className="box_wrap">
                                                                            <Link to={`${urlBase}/news/${news.node.slug}`}>
                                                                                <div className="image">
                                                                                    {/* <img src={news.node.acf.newsImage.sourceUrl} alt="" /> */}
                                                                                    <img src={news.node.acf.newsImage.sourceUrl} srcSet={news.node.acf.newsImage.srcSet} alt="" />
                                                                                </div>
                                                                                <p dangerouslySetInnerHTML={{__html:news.node.title}}></p>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </Slider>
                                                    </div>
                                                </div>
                                                </Fade>
                                            </section>
                                        </React.Fragment>
                                        )
                                    } else {
                                        return(
                                            <section className="home_page__Featured" key={work.node.id}>
                                                {/* {work.node.acf.workImage.mediaDetails.sizes.map(imageSrc => {
                                                    for(let i=0; i<5; i++){
                                                        if(imageSrc.name === 'large'){
                                                            return <img src={imageSrc.sourceUrl} alt="" />
                                                            i = 4;
                                                        }else if(imageSrc.name === 'medium') {
                                                            return <img src={imageSrc.sourceUrl} alt="" />
                                                            i = 4;
                                                        }  
                                                        i++;                                             
                                                    }
                                                })} */}
                                                <img src={work.node.acf.workImage.sourceUrl} srcSet={work.node.acf.workImage.srcSet} alt="" />
                                                <div className="home_page__Featured__blackOverlay"></div>
                                                <Fade bottom distance={`100px`}>
                                                    <div className="home_page__Featured__Content container-fluid-custom-only">
                                                        <h2><Link to={`${urlBase}/work/${work.node.slug}`}>{work.node.acf.workExcerpt}</Link></h2>
                                                        <Link to={`${urlBase}/work/${work.node.slug}`} className="home__Featured__Link">続きを見る</Link>
                                                    </div>
                                                </Fade>
                                            </section>
                                        )
                                    }
                                })}
                            </>
                        )
                    }
                    if(pageContext.urlBase === 'en'){
                        return(
                            <>
                                {props.homepageConfigEN.edges.map(homepage => {
                                    if(process.env.GATSBY_OFFICE_NAME === 'WK_SEOUL'){
                                        console.log(homepage)
                                        if(homepage.node.related_office.relatedOffice !== 'wktokyo'){
                                            return(
                                                <section className="home_page__Featured home_page__Featured__Video" key={homepage.node.id} style={{backgroundImage: "url(" + homepage.node.config.poster._full + ")"}}>
                                                    <iframe src={`https://player.vimeo.com/video/${homepage.node.config.video_id}?app_id=122963&background=1&player_id=player`} width="1280" height="720" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen title="W+K Tokyo Website" id="player" crossOrigin="anonymous"></iframe>
                                                    <div className="home_page__Featured__blackOverlay"></div>
                                                    <Fade bottom distance={`100px`}>
                                                        <div className="home_page__Featured__Content container-fluid-custom-only">
                                                            <h2>{homepage.node.config.home_description}</h2>
                                                        </div>
                                                    </Fade>
                                                </section>
                                            )
                                        }
                                    }
                                    else{
                                        if(homepage.node.related_office.relatedOffice !== 'wkseoul'){
                                            return(
                                                <section className="home_page__Featured home_page__Featured__Video" key={homepage.node.id} style={{backgroundImage: "url(" + homepage.node.config.poster._full + ")"}}>
                                                    <iframe src={`https://player.vimeo.com/video/${homepage.node.config.video_id}?app_id=122963&background=1&player_id=player`} width="1280" height="720" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen title="W+K Tokyo Website" id="player" crossOrigin="anonymous"></iframe>
                                                    <div className="home_page__Featured__blackOverlay"></div>
                                                    <Fade bottom distance={`100px`}>
                                                        <div className="home_page__Featured__Content container-fluid-custom-only">
                                                            <h2>{homepage.node.config.home_description}</h2>
                                                        </div>
                                                    </Fade>
                                                </section>
                                            )
                                        }
                                    }
                                })}
                                {props.languageEN.edges.map((work, index) => {
                                    if(index === 0){
                                        return(
                                        <React.Fragment key={work.node.id}>
                                            <section className="home_page__Featured">
                                                {/* {work.node.acf.workImage.mediaDetails.sizes.map(imageSrc => {
                                                    for(let i=0; i<5; i++){
                                                        if(imageSrc.name === 'large'){
                                                            return <img src={imageSrc.sourceUrl} alt="" />
                                                            i = 4;
                                                        }else if(imageSrc.name === 'medium') {
                                                            return <img src={imageSrc.sourceUrl} alt="" />
                                                            i = 4;
                                                        }  
                                                        i++;                                             
                                                    }
                                                })} */}
                                                <img src={work.node.acf.workImage.sourceUrl} srcSet={work.node.acf.workImage.srcSet} alt="" />
                                                <div className="home_page__Featured__blackOverlay"></div>
                                                <Fade bottom distance={`100px`}>
                                                <div className="home_page__Featured__Content container-fluid-custom-only">
                                                    <h2><Link to={`${urlBase}/work/${work.node.slug}`}>{work.node.acf.workExcerpt}</Link></h2>
                                                    <Link to={`${urlBase}/work/${work.node.slug}`}  className="home__Featured__Link">See more</Link>
                                                </div>
                                                </Fade>
                                            </section>
                                            <section className="news_Home_Page">
                                                <Fade bottom distance={`100px`}>
                                                <div className="slick_slider container-fluid-custom-only">
                                                    <h3>Recent News</h3>
                                                    <div className="slider">
                                                        <Slider {...settings}>
                                                            {props.featuredNewsEN.edges.map(news => { 
                                                                return(
                                                                    
                                                                    <div className="slides" key={news.node.id}>
                                                                        <div className="box_wrap">
                                                                            <Link to={`${urlBase}/news/${news.node.slug}`}>
                                                                                <div className="image">
                                                                                <img src={news.node.acf.newsImage.sourceUrl} srcSet={news.node.acf.newsImage.srcSet} alt="" />
                                                                                </div>
                                                                                <p dangerouslySetInnerHTML={{__html:news.node.title}}></p>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </Slider>
                                                    </div>
                                                </div>
                                                </Fade>
                                            </section>
                                        </React.Fragment>
                                        )
                                    } else {
                                        return(
                                            <section className="home_page__Featured" key={work.node.id}>
                                               {/* {work.node.acf.workImage.mediaDetails.sizes.map(imageSrc => {
                                                    for(let i=0; i<5; i++){
                                                        if(imageSrc.name === 'large'){
                                                            return <img src={imageSrc.sourceUrl} alt="" />
                                                            i = 4;
                                                        }else if(imageSrc.name === 'medium') {
                                                            return <img src={imageSrc.sourceUrl} alt="" />
                                                            i = 4;
                                                        }  
                                                        i++;                                             
                                                    }
                                                })} */}
                                                <img src={work.node.acf.workImage.sourceUrl} srcSet={work.node.acf.workImage.srcSet} alt="" />
                                                <div className="home_page__Featured__blackOverlay"></div>
                                                <Fade bottom distance={`100px`}>
                                                    <div className="home_page__Featured__Content container-fluid-custom-only">
                                                        <h2><Link to={`${urlBase}/work/${work.node.slug}`}>{work.node.acf.workExcerpt}</Link></h2>
                                                        <Link to={`${urlBase}/work/${work.node.slug}`} className="home__Featured__Link">See more</Link>
                                                    </div>
                                                </Fade>
                                            </section>
                                        )
                                    }
                                })}
                            </>
                        )
                    }
                    if(pageContext.urlBase === 'ko'){
                        return(
                            <>
                                {props.homepageConfigKO.edges.map(homepage => {
                                return(
                                    <section className="home_page__Featured home_page__Featured__Video" key={homepage.node.id} style={{backgroundImage: "url(" + homepage.node.config.poster._full + ")"}}>
                                        <iframe src={`https://player.vimeo.com/video/${homepage.node.config.video_id}?app_id=122963&background=1&player_id=player`} width="1280" height="720" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen title="W+K Tokyo Website" id="player" crossOrigin="anonymous"></iframe>
                                        <div className="home_page__Featured__blackOverlay"></div>
                                        <Fade bottom distance={`100px`}>
                                            <div className="home_page__Featured__Content container-fluid-custom-only">
                                                <h2>{homepage.node.config.home_description}</h2>
                                            </div>
                                        </Fade>
                                    </section>
                                )
                                })}
                                {props.languageKO.edges.map((work, index) => {
                                    if(index === 0){
                                        return(
                                        <React.Fragment key={work.node.id}>
                                            <section className="home_page__Featured">
                                                {/* {work.node.acf.workImage.mediaDetails.sizes.map(imageSrc => {
                                                    for(let i=0; i<5; i++){
                                                        if(imageSrc.name === 'large'){
                                                            return <img src={imageSrc.sourceUrl} alt="" />
                                                            i = 4;
                                                        }else if(imageSrc.name === 'medium') {
                                                            return <img src={imageSrc.sourceUrl} alt="" />
                                                            i = 4;
                                                        }  
                                                        i++;                                             
                                                    }
                                                })} */}
                                                <img src={work.node.acf.workImage.sourceUrl} srcSet={work.node.acf.workImage.srcSet} alt="" />
                                                <div className="home_page__Featured__blackOverlay"></div>
                                                <Fade bottom distance={`100px`}>
                                                <div className="home_page__Featured__Content container-fluid-custom-only">
                                                    <h2><Link to={`${urlBase}/work/${work.node.slug}`}>{work.node.acf.workExcerpt}</Link></h2>
                                                    <Link to={`${urlBase}/work/${work.node.slug}`}  className="home__Featured__Link">더보기</Link>
                                                </div>
                                                </Fade>
                                            </section>
                                            <section className="news_Home_Page">
                                                <Fade bottom distance={`100px`}>
                                                <div className="slick_slider container-fluid-custom-only">
                                                    <h3>최근 뉴스</h3>
                                                    <div className="slider">
                                                        <Slider {...settings}>
                                                            {props.featuredNewsKO.edges.map(news => {
                                                                return(
                                                                    <div className="slides" key={news.node.id}>
                                                                        <div className="box_wrap">
                                                                            <Link to={`${urlBase}/news/${news.node.slug}`}>
                                                                                <div className="image">
                                                                                    <img src={news.node.acf.newsImage.sourceUrl} srcSet={news.node.acf.newsImage.srcSet} alt="" />
                                                                                </div>
                                                                                <p dangerouslySetInnerHTML={{__html:news.node.title}}></p>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </Slider>
                                                    </div>
                                                </div>
                                                </Fade>
                                            </section>
                                        </React.Fragment>
                                        )
                                    } else {
                                        return(
                                            <section className="home_page__Featured" key={work.node.id}>
                                                {/* {work.node.acf.workImage.mediaDetails.sizes.map(imageSrc => {
                                                    for(let i=0; i<5; i++){
                                                        if(imageSrc.name === 'large'){
                                                            return <img src={imageSrc.sourceUrl} alt="" />
                                                            i = 4;
                                                        }else if(imageSrc.name === 'medium') {
                                                            return <img src={imageSrc.sourceUrl} alt="" />
                                                            i = 4;
                                                        }  
                                                        i++;                                             
                                                    }
                                                })} */}
                                                <img src={work.node.acf.workImage.sourceUrl} srcSet={work.node.acf.workImage.srcSet} alt="" />
                                                <div className="home_page__Featured__blackOverlay"></div>
                                                <Fade bottom distance={`100px`}>
                                                    <div className="home_page__Featured__Content container-fluid-custom-only">
                                                        <h2><Link to={`${urlBase}/work/${work.node.slug}`}>{work.node.acf.workExcerpt}</Link></h2>
                                                        <Link to={`${urlBase}/work/${work.node.slug}`} className="home__Featured__Link">더보기</Link>
                                                    </div>
                                                </Fade>
                                            </section>
                                        )
                                    }
                                })}
                            </>
                        )
                    }
                }} />
            </article>
        </Layout>
    )
    
}

// export default class home extends Component {
// //   container = React.createRef()

// //   bindScrollSnap() {
// //       function callback() {
// //           console.log(snapObject.scrollStart)
// //       }

// //       const element = this.container.current
// //       const snapObject = new ScrollSnap(element, snapConfig)
// //       snapObject.bind(callback)
// //   }

// //   componentDidMount() {
// //       this.bindScrollSnap();
// //   }
//   render() {
    
//   }
// }